@import 'libs/shared/src/lib/utils/fonts';

.h-inherit {
  height: inherit;
}

.w-fit-content {
  width: fit-content;
}

.bold {
  font-weight: bold;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: end;
}

.items-center {
  align-items: center;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.end {
  text-align: end !important;
}

.start {
  text-align: start;
}

.padding-0 {
  padding: 1rem 1.5rem !important;
}

.padding-1 {
  padding: 10px;
}

.padding-2 {
  padding: 24px;
}

.padding-3 {
  padding: 24px;
}

.p-r-1 {
  padding-right: 4px !important;
}

.p-r-2 {
  padding-right: 15px !important;
}

.p-r-3 {
  padding-right: 24px !important;
}

.p-t-0 {
  padding-top: 10px;
}

.p-t-1 {
  padding-top: 15px;
}

.p-t-1-1 {
  padding-top: 20px;
}

.p-t-1-2 {
  padding-top: 32px;
}

.p-t-2 {
  padding-top: 40px;
}

.p-t-3 {
  padding-top: 60px;
}

.p-b-1 {
  padding-bottom: 16px;
}

.p-b-1-1 {
  padding-bottom: 20px;
}

.p-b-1-2 {
  padding-bottom: 24px;
}

.p-b-2 {
  padding-bottom: 32px;
}

.p-l-1 {
  padding-left: 4px;
}

.p-l-2 {
  padding-left: 10px;
}

.p-l-2-1 {
  padding-left: 12px;
}

.p-l-2-2 {
  padding-left: 17px;
}

.p-l-3 {
  padding-left: 24px;
}

.p-l-3-1 {
  padding-left: 28px !important;
}

.p-l-4 {
  padding-left: 49px;
}

.m-y-2 {
  margin: 2rem 0 !important;
}

.m-0 {
  margin: 10px;
}

.m-0-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 20px;
}

.margin-1 {
  margin: 24px !important;
}

.m-2 {
  margin: 30px;
}

.negative-m-t-0 {
  margin-top: -10px;
}

.mt-10 {
  margin-top: 10px;
}

.m-t-0 {
  margin-top: 13px;
}

.m-t-0-1 {
  margin-top: 8px;
}

.m-t-1 {
  margin-top: 16px !important;
}

.m-t-1-1 {
  margin-top: 24px;
}

.m-t-2 {
  margin-top: 32px;
}

.m-t-2-2 {
  margin-top: 37px;
}

.m-t-2-3 {
  margin-top: 44px;
}

.m-t-3 {
  margin-top: 60px;
}

.m-t-4 {
  margin-top: 100px;
}

.m-b-0 {
  margin-bottom: 5px;
}

.m-b-0-0 {
  margin-bottom: 0;
}

.m-b-0-1 {
  margin-bottom: 8px;
}

.m-b-1 {
  margin-bottom: 16px !important;
}

.m-b-1-2 {
  margin-bottom: 24px;
}

.m-b-1-3 {
  margin-bottom: 32px;
}

.m-b-2 {
  margin-bottom: 40px;
}

.m-b-3 {
  margin-bottom: 60px;
}

.m-b-4 {
  margin-bottom: 100px;
}

.m-r-0 {
  margin-right: 4px !important;
}

.m-r-0-1 {
  margin-right: 8px !important;
}

.m-r-0-2 {
  margin-right: 12px !important;
}

.m-r-1 {
  margin-right: 16px !important;
}

.m-r-1-2 {
  margin-right: 24px !important;
}

.m-r-2 {
  margin-right: 32px !important;
}

.m-r-3 {
  margin-right: 48px !important;
}

.m-l-0 {
  margin-left: 4px;
}

.m-l-0-1 {
  margin-left: 10px;
}

.m-l-1 {
  margin-left: 16px !important;
}

.m-l-1-2 {
  margin-left: 24px !important;
}

.m-l-2 {
  margin-left: 32px !important;
}

.m-l-3 {
  margin-left: 48px !important;
}

.m-l-4 {
  margin-left: 4rem !important;
}

.items-center {
  align-items: center;
}

.d-flex {
  display: flex !important;
  align-items: center;
}

.d-grid {
  display: grid;
  align-items: center;
}

.d-evenly {
  display: flex !important;
  justify-content: space-evenly;
}

.d-block {
  display: block;
  align-items: center;
}

.d-inline {
  display: inline;
}

.justify {
  text-align: justify;
}

.d-table {
  display: table;
}

.full-height {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.w-max {
  width: max-content !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-76 {
  width: 76%;
}

.w-60 {
  width: 60%;
}

.w-26 {
  width: 26%;
}

.w-54 {
  width: 54%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-24 {
  width: 24%;
}

.w-20 {
  width: 20% !important;
}

.w-16 {
  width: 16.67%;
}

.w-10 {
  width: 10%;
}

.no-border {
  border: none !important;
}

.startBlock {
  display: flex !important;
  justify-content: flex-start !important;
}

.centerBlock {
  display: flex !important;
  justify-content: center !important;
}

.endBlock {
  display: flex !important;
  justify-content: flex-end !important;
}

.between {
  display: flex !important;
  justify-content: space-between;
}

.logo {
  width: 90.03px;
  display: block;
  margin: 0 auto;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.divider {
  margin-top: 20px;

  .arrow-left {
    display: inline-block;
    width: 35px;
    height: 1px;
    background-color: #cccccc;
    margin-bottom: 2px;
  }

  .arrow-right {
    display: inline-block;
    width: 35px;
    height: 1px;
    background-color: #cccccc;
    margin-bottom: 2px;
  }

  .dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #cccccc;
    margin: 0 5px;
  }
}

.example-spacer {
  flex: 1 1 auto;
}

.example-spacer-1 {
  flex: 1 auto;
}

.mat-paginator-page-size {
  flex: 1;
}

.colorPrimary {
  color: #6e54fc !important;
}

.colorAccent {
  color: #ffcd1c !important;
}

.colorWarning {
  color: #bb0000;
}

.colorName {
  color: #999999;
}

.colorDefault {
  color: #333333;
}

.pointer-cursor {
  cursor: pointer;
}

.pointer-grab {
  cursor: grab;
}

.disabled-cursor {
  cursor: not-allowed;
}

.underline {
  text-decoration: underline;
}

// Teste Styles

.btnActions {
  padding-top: 40px;
  background-color: #ffffff;

  .btn-save {
    font-family: 'Titillium-regular', sans-serif;
    border: 1px #bcc300 solid;
    color: #bcc300;
  }

  .btn-next {
    font-family: 'Titillium-regular', sans-serif;
    background-color: #bcc300;
    color: #ffffff;
    margin-left: 15px;

    mat-icon {
      margin-left: 70px;
    }
  }

  .btn-back {
    border: 1px solid #5c036f;
    font-family: 'Titillium-regular', sans-serif;
    height: 38px;
  }

  .btn-next:disabled {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
  }

  .btn-save:disabled {
    color: rgba(0, 0, 0, 0.26);
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.btnDelete {
  color: #ff0000;
}

.alert {
  color: #ff7600;
  font-size: 13px;
  font-family: 'Titillium-regular', sans-serif;
}

.alertColor {
  color: #ff7600;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.colorGreen {
  color: rgb(13, 199, 13);
}

.btn__excluir {
  color: #ff0000 !important;
  font-family: 'Titillium-regular', sans-serif;

  mat-icon {
    width: 15px;
    height: 15px;
    font-size: 15px;
  }
}

.loading {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}

.inline {
  display: inline-grid;
}

.sticky {
  width: 100%;
  position: sticky;
  background-color: #ffffff;
  z-index: 10;
  padding-top: 20px;
  top: 0px;
}

.list {
  width: 100%;
  z-index: 5;
  overflow: auto;
}

.horizontal-scroll {
  margin-top: 20px;
  margin-left: 5px;
  overflow-x: auto;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  white-space: nowrap;

  @media (min-width: 768px) {
    justify-content: center;
  }

  span {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    line-height: 26px;
    color: #979797;

    @media (min-width: 768px) {
      margin-left: 30px;
      margin-right: 60px;
    }
  }

  .selected {
    color: #6d54fc !important;
  }
}

.img-container {
  position: relative;
  display: inline-block;

  img {
    width: 100%;
    display: block;
  }

  .absolute {
    background: #ffffff;
    color: #6d54fc;
    position: absolute;
    font-size: 12px;
    top: 12px;
    left: 12px;
    width: 24px;
    height: 24px;
  }

  .absolute2 {
    position: absolute;
    top: 0px;
    left: 35px;
    width: 24px;
    height: 24px;
  }
}

.header-clean {
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #6d54fc;
}

.header-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #292929;
}

.back-to-store {
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  color: #6d54fc;
}

.button-div-border {
  background-color: #ffffff;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px 5px 0px 0px;
  border-top: #4e4e4e;
  width: 100%;
  position: absolute;
  bottom: 0px;
  text-align: center;
}

.align-center {
  height: 100%;
  width: 100%;
  min-height: 150px;
  justify-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.button-div {
  background-color: #ffffff;
  position: absolute;
  margin-bottom: 3px;
  bottom: 0px;
  text-align: center;
  width: 90%;
}

.button-div2 {
  background-color: #ffffff;
  position: absolute;
  margin-bottom: 3px;
  bottom: 10px;
  width: 85%;
}

.max-h-100 {
  max-height: 100vh;
  overflow: hidden;
}

.list-value {
  margin-top: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  align-items: center;
  color: #333333;
}

.center-div {
  text-align: center;
  position: relative;
  justify-content: center;
  top: calc(50% - 10px);
}

.h-100vh {
  height: 100vh;
}

.product-list {
  height: 100%;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.method-card {
  img {
    width: 40px;
    height: 40px;
  }

  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 5px;
}

.banner {
  justify-content: center;
  text-align: center;
  padding: 15px;
  min-height: 71px;
  height: auto;
  width: 100%;
  background: #faedd4;
  font-size: 12px;
  line-height: 20px;
  color: #5b4010;
}

.product-title {
  font-weight: 600;
  font-size: 14px;
  color: #585858;
}

.product-name {
  font-weight: 400;
  font-size: 12px;
  color: #333333;
}

.product-value {
  font-weight: 400;
  font-size: 12px;
  color: #585858;
}

@media (min-width: 1000px) {
  .product-card {
    width: 30%;
    height: auto;
    margin: 10px;
  }
}

.product-card {
  width: 40%;
  margin-bottom: 10px;
}

.gen-logo {
  height: 30px;
  width: 80px;
}

::ng-deep {
  .popoverPrimary {
    background-color: #6051e0 !important;
    border-radius: 6px !important;
    padding: 8px 16px !important;
    color: white !important;
  }
}

.header-drawer {
  position: fixed;
  z-index: 1;
}

.button-drawer {
  position: fixed;
  top: 10px;
  right: 25px;
}

.button-left {
  position: absolute;
  top: -14px;
  left: -12px;
}

.button-right {
  position: absolute;
  top: -16px;
  left: 278px;

  &__large {
    left: 500px;
  }
}

.title-drawer {
  margin-top: 4px;
  margin-left: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.no-action-btn > button {
  pointer-events: none;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 12px;
  width: 100%;

  h3 {
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    margin: 0;
  }
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  p {
    margin: 0;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
  }
}

.modal-footer {
  padding: 10px 24px;

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid #f1f1f1;
    padding-top: 18px;
    margin-bottom: 16px;
  }

  .button-content {
    border-radius: 8px;
    padding: 10px 24px;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;

    &__red {
      background-color: #c73b51;
    }
  }
}

.button-confirm {
  width: 100%;
  button {
    font-weight: 500;
    border: none;
    font-size: 16px;
    font-family: 'Inter';
    width: 100%;
    padding: 10px 18px;
    border-radius: 8px;
    background: #10ad7d;
    cursor: pointer;
    color: var(--neutrals-off-white, #fcfcfc);
    transition: all 0.3s;

    &:hover {
      box-shadow: 0px 0px 8px 0px rgba(72, 206, 166, 0.5);
      background: var(--sucess-green, #48cea6);
    }

    &:active {
      background: var(--sucess-green, #48cea6);
      box-shadow: none;
    }
  }
}

.bd-16 {
  border-radius: 16px;
}

.pts-3 {
  padding: 1rem 1rem 0rem 1rem;

  @media (min-width: 768px) {
    padding: 3rem 3rem 0rem 3rem;
  }
}

.pbs-3 {
  padding: 0rem 1rem 1rem 1rem;

  @media (min-width: 768px) {
    padding: 0rem 3rem 3rem 3rem;
  }
}

.p-3 {
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 3rem;
  }
}

.n-m-t-0 {
  margin-top: -15px;
}
