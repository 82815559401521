/* You can add global styles to this file, and also import other style files */
@import 'libs/shared/src/lib/utils/utils';
@import 'libs/shared/src/lib/utils/fonts';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Artegra', sans-serif;
}

h1,
h2,
h3 {
  font-family: 'Artegra-Bold';
}

.primary-color {
  color: #6e54fc !important;
}

.accent-color {
  color: #000000;
}

.warning-color {
  color: #ffcd1c;
}

.error-color {
  color: #fc4e69 !important;
}

.success-color {
  color: #389e0d;
}

.deep-green-color {
  color: #008d62 !important;
}

p {
  b,
  strong {
    font-family: 'Artegra-Bold';
  }

  a {
    font-family: 'Artegra-Semibold';
  }
}

.defaultModal .ant-modal-content {
  min-height: 100vh;
}

.ant-modal-header {
  padding: 0 !important;
  padding-top: 16px !important;
  margin-bottom: 16px !important;
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.ant-modal-body {
  padding: 0 !important;
}

.ant-modal-footer {
  padding: 0 !important;
  border: 0 !important;
}

// To onboarding modal.
.ant-notification {
  z-index: 999;
}

.cdk-virtual-scrollable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #f0f2f5 !important;
  border: 1px solid #f0f2f5 !important;
  border-radius: 8px !important;
}

.cdk-virtual-scrollable::-webkit-scrollbar {
  width: 5px !important;
}

.cdk-virtual-scrollable::-webkit-scrollbar-thumb {
  background: #6d54fc !important;
  border-radius: 8px !important;
}

.custom-modal {
  .ant-modal-content {
    padding: 24px !important;
  }

  .ant-modal-header {
    padding-top: 0 !important;
  }
}

.date-content {
  padding: 24px !important;
}

.button-cancel {
  button {
    background: none;
    border: 1.5px solid var(--error-dark-red, #c73b51);
    border-radius: 8px;
    font-weight: 500;
    width: 135px;
    height: 44px;
    font-size: 16px;
    font-family: 'Inter';
    padding: 10px 18px;
    cursor: pointer;
    color: #c73b51;
    transition: all 0.3s;

    &:hover {
      box-shadow: 0px 0px 8px 0px rgba(252, 78, 105, 0.5);
      background-color: #fcfcfc;
      color: #c73b51;
    }
  }
}

.upload-container {
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
  background: var(--neutrals-medium-white, #f5f5f5);
  width: 52px;
  height: 52px;
  flex-shrink: 0;
}
