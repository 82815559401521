@import '../../../../../node_modules/ng-zorro-antd/ng-zorro-antd.less';
//Fonts
@import url('./_fonts.less');

//Colors
@primary-dark: #342b78;
@white: #ffffff;
@grey: #979797;
@light-grey: #c9c9c9;
@purple-50: rgba(96, 81, 224, 0.5);

// NZ-Defaults Override -------------- /
@primary-color: @primary-dark;
@accent-color: #000000;
@warning-color: #ffcd1c;
@error-color: #c73b51;
@zindex-notification: 990;

// Select
@select-dropdown-height: 40px;
@select-dropdown-height-base: 40px;
@select-dropdown-height-lg: 44px;
@select-dropdown-height-sm: 32px;
@select-dropdown-padding: 8px, 16px, 8px, 16px;
@select-dropdown-focus-border-color: @primary-dark;
@select-dropdown-color: #333333;
@select-dropdown-border-color: #e0dddd;
@select-dropdown-bg: #fff;
@select-dropdown-addon-bg: #e4e4e4;
@select-dropdown-hover-border-color: @primary-dark;
@select-disabled-bg: #f1f1f1;
@select-border-radius: 8px;
@dropdown-border-radius: 8px;

// Input
@input-height-base: 40px;
@input-height-lg: 44px;
@input-height-sm: 32px;
@input-padding: 8px, 16px, 8px, 16px;
@input-placeholder-color: @light-grey;
@input-focus-border-color: @primary-dark;
@input-color: #333333;
@input-border-color: #e0dddd;
@input-bg: @white;
@input-addon-bg: #e4e4e4;
@input-hover-border-color: @primary-dark;
@input-disabled-bg: #f1f1f1;

//Primary
@btn-main: @primary-dark;
@btn-hover: #6051e0;
@btn-disabled: #a096e6;

//Confirm
@confirm-main: #10ad7d;
@confirm-hover: #48cea6;
@confirm-disabled: #b2ecdb;

//Error
@error-main: #c73b51;
@error-hover: #fc4e69;
@error-disabled: #feb8c3;

//Text
@text-black: #181818;
@text-white: #fcfcfc;

//Buttons
@btn-border-radius-base: 8px;

//Defaults
@box-shadow-sm: 0px 0px 8px;
@box-shadow-md: 0px 0px 12px;
@border: 1.5px solid;

//Table
@table-bg: @white;
@table-header-bg: @white;
@table-footer-bg: @white;
@table-font-size: 16px;

// Table
th,
td {
  padding: 16px !important;
  font-family: 'Inter';
  line-height: 19.36px;
}

.ant-table-thead > tr > td {
  font-weight: 400;
}

.ant-table-thead > tr > th {
  font-weight: 500;
}

.ant-table-pagination.ant-pagination {
  padding: 16px 8px;
  background-color: @table-bg;
  margin: 0px;
}

.ant-pagination.mini .ant-pagination-options-size-changer {
  margin-left: 10px;

  .ant-select-selector {
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    padding: 0px 16px;
    gap: 8px;
    background-color: @text-white;
    color: #666666;
    line-height: 19.36px;
  }
}

.ant-table-header {
  height: 57px;
}

.ant-table.ant-table-middle .ant-table-footer {
  padding: 0px;
}

.ant-pagination.mini .ant-pagination-item {
  margin: 0px 9px;
  min-width: 22px;
  height: 22px;

  a {
    color: @grey;
    transition-duration: 0ms;
  }
}

.ant-pagination-item-active {
  &:extend(.ant-pagination-item);
  border-radius: 50%;
  border: 0px;
  background-color: @primary-dark;

  a {
    color: @text-white !important;
  }
}

.ant-btn {
  border-radius: @btn-border-radius-base;
  gap: 10px;
  padding: 10px 32px;
  font-size: 16px;
  height: auto;
  background-color: @white;
  color: @btn-main;
  border: @border @btn-main;
  align-items: center;
  font-family: 'Inter';

  .anticon > * {
    color: @btn-main;
  }

  &:hover {
    border: @border @btn-hover;
    box-shadow: @box-shadow-sm @btn-main;
    color: @btn-hover;

    .anticon > * {
      color: @btn-hover;
    }
  }

  &:active {
    border: @border @btn-hover;
    color: @btn-hover;
  }

  &:disabled {
    background-color: @white;
    border: @border @btn-disabled !important;
    color: @btn-disabled;
  }
}

.ant-btn-primary {
  background-color: @btn-main;
  color: @text-white !important;

  .anticon > * {
    color: @text-white !important;
  }

  &:hover {
    background-color: @btn-hover;
    box-shadow: @box-shadow-sm @btn-main;
    border-color: @btn-hover;
  }

  &:active {
    color: @text-white;
    background-color: @btn-hover;
  }

  &:disabled {
    color: @text-white !important;
    background-color: #c9c9c9 !important;
    border: none !important;
  }
}

.btn-confirm-primary {
  &:extend(.ant-btn-primary);
  color: @text-white !important;
  background-color: @confirm-main !important;
  border: 0px;

  .anticon > * {
    color: @text-white !important;
  }

  &:hover {
    background-color: @confirm-hover;
    box-shadow: @box-shadow-sm @confirm-main;
    border-color: @confirm-hover;
  }

  &:active {
    background-color: @confirm-hover;
    color: @white !important;
    border: none !important;
  }

  &:disabled {
    background-color: @confirm-disabled !important;
    border: 0px !important;
    color: @white !important;
  }
}

.btn-confirm {
  &:extend(.ant-btn);
  background-color: @white !important;
  color: @confirm-main;
  border: @border @confirm-main;

  .anticon > * {
    color: @confirm-main;
  }

  &:hover {
    border: @border @confirm-hover;
    color: @confirm-hover;
    box-shadow: @box-shadow-sm @confirm-main;

    .anticon > * {
      color: @confirm-hover;
    }
  }

  &:active {
    border: @border @confirm-hover;
    color: @confirm-hover;
  }

  &:disabled {
    border: @border @confirm-disabled !important;
    color: @confirm-disabled !important;
  }
}

.btn-error-primary {
  &:extend(.ant-btn-primary);
  color: @text-white !important;
  background-color: @error-main;
  border: 0px;

  .anticon > * {
    color: @text-white !important;
  }

  &:hover {
    background-color: @error-hover;
    box-shadow: @box-shadow-sm @error-main;
    border-color: @error-main;
  }

  &:active {
    background-color: @error-hover;
  }

  &:focus {
    background-color: @error-hover;
  }

  &:disabled {
    background-color: @error-disabled !important;
    border: 0px !important;
  }
}

.btn-error {
  &:extend(.ant-btn);
  background-color: @white !important;
  color: @error-main;
  border: @border @error-main;

  .anticon > * {
    color: @error-main;
  }

  &:hover {
    border: @border @error-hover;
    color: @error-hover;
    box-shadow: @box-shadow-sm @error-main;

    .anticon > * {
      color: @error-hover;
    }
  }

  &:active {
    border: @border @error-hover;
    color: @error-hover;
  }

  &:disabled {
    border: @border @error-disabled !important;
    color: @error-disabled !important;
  }
}

.ant-btn-circle {
  min-width: 32px;
  min-height: 32px;
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  border: none !important;
  margin: 5px;
}

// Custom Font Classes
.title {
  font-family: 'Artega-Sans';
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: @text-black;

  &-semi {
    &:extend(.title);
    font-family: 'Artegra-Semibold';
  }

  &-bold {
    &:extend(.title);
    font-family: 'Artegra-Bold';
  }
}

.subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: @text-black;
  font-weight: 500;

  &-light {
    &:extend(.subtitle);
    font-weight: 400;
  }

  &-bold {
    &:extend(.subtitle);
    font-weight: 600;
  }
}

.body-txt {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: @text-black;
  font-weight: 400;

  &-light {
    &:extend(.body-txt);
    font-weight: 300;
  }

  &-bold {
    &:extend(.body-txt);
    font-weight: 500;
  }
}

.small {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: @text-black;
  font-weight: 400;
  word-break: break-all;

  &-light {
    &:extend(.small);
    font-weight: 300;
  }

  &-bold {
    &:extend(.small);
    font-weight: 500;
  }
}

.smaller {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: @text-black;
  font-weight: 400;

  &-light {
    &:extend(.smaller);
    font-weight: 300;
  }

  &-bold {
    &:extend(.smaller);
    font-weight: 500;
  }
}

.button-txt {
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 24px;

  @media (min-width: 1024px) {
    font-family: 'Inter';
  }
}

// Input
.ant-input {
  border-radius: @btn-border-radius-base !important;
  resize: none;
}

.ant-input-affix-wrapper {
  border-radius: 8px;
}

.ant-form-item-label > label {
  height: 20px;
}

// select
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #e7e3ff !important;
  color: #6d54fc;
}

.ant-select-dropdown {
  padding: 1px !important;
  padding-bottom: 10px !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #6d54fc;
  font-weight: 600;
  background-color: #f5f0ff;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
  .ant-select-selection-search-input {
  height: 48px !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 48px !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px !important;
}

.ant-select {
  border-radius: @btn-border-radius-base !important;
}

.ant-select-selector {
  border-radius: @btn-border-radius-base !important;
}

.ant-radio-wrapper {
  .ant-radio {
    .ant-radio-inner {
      border-color: @light-grey;
      background-color: @white;
      box-shadow: none;

      &:hover {
        border-color: @primary-color;
        background-color: @white;
        box-shadow: @box-shadow-md @purple-50;
      }
    }

    &:hover .ant-radio-inner {
      border-color: @primary-color;
      background-color: @primary-color;
      box-shadow: @box-shadow-md @purple-50;
    }

    &:checked .ant-radio-inner {
      border-color: @primary-color;
      background-color: @primary-color;
      box-shadow: none;
    }
  }

  &.ant-radio-wrapper-disabled .ant-radio-inner {
    border-color: var(--neutrals-grey, #c9c9c9) !important;
    background-color: var(--neutrals-grey, #c9c9c9) !important;
    box-shadow: none !important;
  }

  &.ant-radio-wrapper-disabled .ant-radio .ant-radio-inner:hover {
    border-color: var(--neutrals-grey, #c9c9c9) !important;
    background-color: var(--neutrals-grey, #c9c9c9) !important;
    box-shadow: none !important;
  }
}

.ant-radio-wrapper .ant-radio:hover .ant-radio-inner {
  border-color: @primary-color;
  background-color: @white;
  box-shadow: @box-shadow-md @purple-50;
}

.ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
  border-color: @primary-color !important;
  background-color: @primary-color !important;
  box-shadow: none;
}

.ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner::after {
  background-color: white !important;
  border-color: @primary-color !important;
  box-shadow: none;
}

.ant-radio-wrapper.ant-radio-wrapper-disabled .ant-radio-inner::after {
  background-color: white;
  border-color: var(--neutrals-grey, #c9c9c9);
  box-shadow: none;
}

// slider

.ant-slider-handle:focus {
  border-color: #5d5593;
  outline: none;
  box-shadow: none !important;
}
// checkbox
nz-checkbox,
label,
span {
  .ant-checkbox {
    width: 16px !important;
    height: 16px !important;
    font-size: 16px !important;
    border-radius: 4px;

    &-unchecked {
      .ant-checkbox-inner {
        border-color: @grey;
      }
    }

    &:checked {
      box-shadow: @box-shadow-md @purple-50;
    }

    &:hover {
      box-shadow: @box-shadow-md @purple-50;
    }

    &:focus-within .ant-radio-inner {
      box-shadow: @box-shadow-md @purple-50;
    }
  }
}

.ant-checkbox-wrapper ant-checkbox-checked {
  box-shadow: @box-shadow-md @purple-50;
}

// date picker
.ant-picker {
  border: 1px solid #e0dddd;
  border-radius: @btn-border-radius-base;
}

.ant-calendar-picker:hover {
  border-color: @primary-color !important;
}

.ant-calendar-picker:focus {
  border-color: @primary-color !important;
}

.ant-calendar-picker-input input::placeholder {
  color: @light-grey;
}

// card
.ant-card {
  box-shadow: 0px 4px 60px rgba(172, 186, 202, 0.1);
  border-radius: 16px;
}

// modal
.ant-modal-content {
  .ant-modal-header {
    border-radius: 16px !important;
    border: 0px;
  }

  border-radius: 16px;
  background: @white;
  box-shadow: 0px 0px 4px rgba(120, 141, 150, 0.12);
}

.textarea {
  resize: none;
}

// Avatar
.ant-avatar {
  width: 64px;
  height: 64px;
  line-height: 70px;
  font-size: 32px;
  font-family: 'Artega-Sans';
  font-weight: 200 !important;
  border-radius: 50%;

  .ant-avatar-string {
    border-radius: 50%;
    width: 64px;
    height: 64px;
  }

  img:hover {
    mix-blend-mode: multiply;
  }
}

.ant-avatar:hover {
  background: linear-gradient(0deg, rgba(209, 197, 252, 0.25), rgba(209, 197, 252, 0.25));
  box-shadow: 0px 0px 8px rgba(96, 81, 224, 0.5);
}

.ant-avatar-lg {
  &:extend(.ant-avatar);
  width: 80px;
  height: 80px;
  line-height: 90px;
  font-size: 40px;

  .ant-avatar-string {
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
}

.ant-avatar-sm {
  &:extend(.ant-avatar);
  width: 48px;
  height: 48px;
  line-height: 50px;
  font-size: 24px;

  .ant-avatar-string {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
}

// Progress Bar
.ant-progress-bg {
  background-color: @primary-dark;
}

.ant-progress-inner {
  background-color: @light-grey;
}

//Tags
.ant-tag {
  padding: 4px 10px;
  border-radius: 4px;
  border: none;
}

.ant-tag-green {
  color: #008d62 !important;
  background: rgba(178, 236, 219, 0.5) !important;
}

.ant-tag-red {
  background-color: #ffe1e2;
  color: #c73b51;
}

//Drawer
.ant-drawer-header {
  z-index: 1;
}

//Tabs

.ant-tabs-tab-btn {
  font-family: 'Inter';
}

.popoverPrimary {
  .ant-popover-arrow-content {
    &::before {
      background-color: #6051e0;
    }
  }

  .ant-popover-inner {
    background-color: #6051e0;
    border-radius: 6px;
    width: 226px;

    .ant-popover-inner-content {
      p,
      li {
        color: #ffffff;
        margin: none;
        font-weight: 300;
      }

      a {
        color: #ffffff;
        font-weight: 400;
      }
    }
  }
}
